<template>
  <div>
    <commonHeader :showHeader="!isDeviceApp" />
    <van-tabs v-model="active" class="reset-style" sticky offset-top="0" @change="tabsChange">
      <van-tab :title="tab.text" v-for="(tab, tabIndex) in orderList" :key="tabIndex">
        <van-list :finished="activeOrderList.finished" :loading="activeOrderList.loading" @load="onLoad">
          <div class="order-list-card" v-for="(item, index) in activeOrderList.list" :key="index"
            @click="cardClick(item)">
            <div class="header van-row--flex van-row--justify-space-between">
              <div class="time">
                下单时间: <span class="day">{{ item.created_time }}</span>
              </div>
              <div class="state state--success" v-if="item.status === 2">
                拼团成功
              </div>
              <div class="content-item state state--continue" v-else-if="item.status === 1">
                拼团中
              </div>
              <div class="content-item state state--fail" v-else-if="item.status === 3">
                退款中
              </div>
              <div class="content-item state state--fail" v-else-if="item.status === 4">
                已退款
              </div>
            </div>
            <div class="content">
              <orderGoodsInforCard :data="item" />
            </div>
          </div>
        </van-list>
        <!--空页面-->
        <van-empty :image="require('@/assets/images/empty/no_order.png')" description="暂无订单"
          v-if="activeOrderList.finished && activeOrderList.list.length === 0" />
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import orderGoodsInforCard from '@/components/order/goods-infor'
import { apiOrderGetList } from '@/api/order'
import commonMixins from "@/mixins/common";
import { updateAppMeta } from '@/utils/appDevice';

export default {
  name: 'list',
  components: {
    orderGoodsInforCard,
  },
  mixins: [commonMixins],
  data() {
    return {
      active: 0,
      orderList: [
        {
          list: [],
          page: 0,
          text: '全部',
          tab: 0,
          finished: false,
          loading: false,
        },
        {
          list: [],
          page: 0,
          text: '拼团中',
          tab: 1,
          finished: false,
          loading: false,
        },
        {
          list: [],
          page: 0,
          text: '拼团成功',
          tab: 2,
          finished: false,
          loading: false,
        },
        {
          list: [],
          page: 0,
          text: '拼团失败',
          tab: 3,
          finished: false,
          loading: false,
        },
      ],
    }
  },
  computed: {
    activeOrderList() {
      return this.orderList[this.active]
    },
    isDeviceApp() {
      return this.$store.getters.isDeviceAppClient
    },
  },
  mounted() {
    this.$util.setShare()
    // 更新app meta信息
    updateAppMeta()
  },
  metaInfo() {
    return {
      meta: [
        { property: 'tiku:share', content: false },//订单列表页面不允许分享
      ],
    }
  },
  methods: {
    getOrderList() {
      let activeOrderList = this.activeOrderList
      activeOrderList.page = activeOrderList.page + 1
      let param = {}
      param.tab = activeOrderList.tab
      param.page = activeOrderList.page
      param.length = 10
      activeOrderList.loading = true
      apiOrderGetList(param).then((res) => {
        activeOrderList.list = activeOrderList.list.concat(res?.data)
        activeOrderList.loading = false

        if (res?.data.length < 10) {
          activeOrderList.finished = true
        }

        if (res.status === -1) {
          this.$router.push({ name: 'login' })
        }
      })
    },
    cardClick(infor) {
      const query = {
        id: infor.id,
        gid: infor.g_id,
        rid: infor.r_id,
      }

      //修复ios app内跳转后 返回按钮返回页面异常
      if (this.isDeviceApp) {
        this.$util.locationHref(window.location.origin + '/wap/order/detail?' + this.$queryString.stringify(query))
        return
      }

      this.$router.push({
        name: 'orderDetail',
        query,
      })

    },
    tabsChange() {
      if (this.activeOrderList.page === 0) {
        // this.getOrderList()
      }
    },
    onLoad() {
      this.getOrderList()
    },
  },
}
</script>

<style scoped lang="scss">
@media screen and (min-width: 600px) {
  // ::v-deep .van-sticky--fixed {
  //   transform: translateX(-50%) !important;
  //   // top: 40px !important;
  // }
}

.order-list-card {
  padding: 0 $containerPadding;
  background-color: #fff;

  &+& {
    margin-top: 0.21rem;
  }

  .header {
    padding-top: 0.43rem;
    padding-bottom: 0.32rem;
    line-height: 1;

    .time {
      font-size: 0.32rem;
      color: #3c3d42;

      .day {
        color: #919499;
      }
    }

    .state {
      &--success {
        color: $color_green;
      }

      &--continue {
        color: #ff6a4d;
      }

      &--fail {
        color: #919499;
      }
    }
  }
}

.van-empty {
  padding-top: 2.8rem;
}
</style>
