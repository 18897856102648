<template>
  <div class="goods-infor">
    <div class="name">{{ data.group_title }}</div>
    <div class="content">
      <div class="content-item price">￥{{ data.amount }}</div>
      <div class="content-item number">数量：{{ data.num }}</div>
      <div class="content-item state" v-if="showFialText">{{ showFialText }}</div>
    </div>
  </div>
</template>

<script>
import { isDeviceAppClient } from '@/cookies/device';

export default {
  name: "infor",
  props: ['data'],
  computed: {
    showFialText() {
      // app里显示退款中
      return isDeviceAppClient() && this.data.refund_status_text
    }
  },
  methods: {

  },
}
</script>

<style lang="scss" scoped>
.goods-infor {
  .name {
    @include ellipsis(2);
    font-size: 0.4rem;
    line-height: 0.56rem;
    color: #333333;
    margin-bottom: .2rem;
  }

  .content {
    padding-bottom: .4rem;

    &-item {
      display: inline-block;
      font-size: 0.32rem;
      color: #919499;

      &+.content-item {
        margin-left: .45rem;
      }

      &.state {
        float: right;
        margin-left: 0;
      }


    }
  }
}

.group-infor {
  background-color: #fff;
  overflow: hidden;

  .tips {
    font-size: 0.32rem;
    line-height: 0.32rem;
    color: #919499;
    text-align: center;
    margin-bottom: .53rem;
  }
}
</style>